.ScrollAreaRoot {
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  --scrollbar-size: 10px;
}

.ScrollAreaViewport {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: inherit;
}

.ScrollAreaScrollbar {
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  background: var(--chakra-colors-gray-200);
  transition: background 160ms ease-out;
}
.ScrollAreaScrollbar:hover {
  background: var(--chakra-colors-gray-300);
}
.ScrollAreaScrollbar[data-orientation='vertical'] {
  width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation='horizontal'] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.ScrollAreaThumb {
  flex: 1;
  background: var(--chakra-colors-gray-600);
  border-radius: var(--scrollbar-size);
  position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}

.ScrollAreaCorner {
  background: var(--chakra-colors-gray-400);
}
