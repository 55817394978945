/* Basic editor styles */
.ProseMirror {
  padding: 0.55rem;
}
.ProseMirror > * + * {
    margin-top: 0.75em;
  }
.ProseMirror ul,
  .ProseMirror ol {
    padding: 0 1rem;
  }
.ProseMirror h1,
  .ProseMirror h2,
  .ProseMirror h3 {
    font-weight: bold;
    line-height: 1.1;
  }
.ProseMirror h1 {
    font-size: 2em;
  }
.ProseMirror h2 {
    font-size: 1.5em;
  }
.ProseMirror h3 {
    font-size: 1.25em;
  }
